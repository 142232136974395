<script setup lang="ts">
const { t } = useI18n()
useSeoMeta({
	title: () => t('pages.home.meta.title'),
	ogTitle: () => t('pages.home.meta.title'),
	description: () => t('pages.home.meta.description'),
	ogDescription: () => t('pages.home.meta.description'),
})
</script>

<template>
	<div>
		<div class="absolute inset-0 h-screen w-screen overflow-hidden bg-blue-100 dark:bg-yellow-700">
			<img
				src="/assets/images/JokeVink_IMG_5759.webp"
				alt="Joke Vink"
				class="h-screen w-screen object-cover object-top dark:hidden"
			/>
			<img
				src="/assets/images/JokeVink_IMG_3961.webp"
				alt="Joke Vink"
				class="h-screen w-screen object-cover object-top hidden dark:block"
			/>
		</div>
		<PageMenu>
			<PageTitle :title="$t('pages.home.title')" />
			<h2>{{ $t('pages.home.tagline') }}</h2>
		</PageMenu>
	</div>
</template>
